import 'select-woo'

function select2Init() {
  $("select.select2").each((i, select) => {
    const $select = $(select);

    const select2Opts = {
      closeOnSelect: $select.prop('multiple'),
      selectionCssClass: ':all:',
      theme: 'bootstrap4'
    };

    const emptyOption = Array.from(select.options).find(o => o.value === '');
    if (emptyOption !== undefined) {
      select2Opts.placeholder = emptyOption.textContent;
      select2Opts.allowClear = true;
    }

    $select.select2(select2Opts);

    $select.on('select2:selecting', (e) => {
      const selectedOption = $select.find(`option[value="${e.params.args.data.id}"]`);
      if ($select.data("only") !== undefined) {
          if($select.data("only") == $(selectedOption).text()){
            $select.prop('multiple', false).val(e.params.args.data.id).trigger('change')
          } else if($select.prop('multiple') == false) {
            e.preventDefault()
          }
        }
    })

    $select.on('select2:unselecting', (e) => {
      const selectedOption = $select.find(`option[value="${e.params.args.data.id}"]`);
      if($select.data("only") == $(selectedOption).text()){
        $select.prop('multiple', true)
      }
    })

    $select.on('select2:unselecting', (e) => {
      //const selectedOption = $select.find('option:selected');
      const selectedOption = $select.find(`option[value="${e.params.args.data.id}"]`);
       // Check if the selected option should not be unselected
      if (selectedOption.data('remove') === false) {
        e.preventDefault();
         $('.location-error').removeClass('hidden');
      } else {
        $('.location-error').addClass('hidden');
      }
    });

    const label = document.createElement('label');
    label.htmlFor = $select.prop('id');
    label.style.display = $select.data('select2').$container.css('display');
    label.classList.add('select2-offscreen'); // Has no effect besides hiding from capybara-select-2 where necessary
    $select.data('select2').$container.wrap(label);

    // https://github.com/select2/select2/issues/5993
    $select.on('select2:open', () => {
      const searchField =
        $select.data('select2').$dropdown.find('.select2-search__field')[0];

      if (searchField === undefined) {
        return;
      }

      searchField.focus();
    });

    $select.on('change', (e) => {
      if (!e.isTrigger) {
        return;
      }

      select.dispatchEvent(new Event('change'));
    });
  });
}

function select2InitModal(modal) {
  $("select.select2").each((i, select) => {
    const $select = $(select);

    const select2Opts = {
      closeOnSelect: $select.prop('multiple'),
      selectionCssClass: ':all:',
      theme: 'bootstrap4',
      dropdownParent: modal
    };

    const emptyOption = Array.from(select.options).find(o => o.value === '');
    if (emptyOption !== undefined) {
      select2Opts.placeholder = emptyOption.textContent;
      select2Opts.allowClear = true;
    }

    $select.select2(select2Opts);

    const label = document.createElement('label');
    label.htmlFor = $select.prop('id');
    label.style.display = $select.data('select2').$container.css('display');
    label.classList.add('select2-offscreen'); // Has no effect besides hiding from capybara-select-2 where necessary
    $select.data('select2').$container.wrap(label);

    // https://github.com/select2/select2/issues/5993
    $select.on('select2:open', () => {
      const searchField =
        $select.data('select2').$dropdown.find('.select2-search__field')[0];

      if (searchField === undefined) {
        return;
      }

      searchField.focus();
    });

    $select.on('change', (e) => {
      if (!e.isTrigger) {
        return;
      }

      select.dispatchEvent(new Event('change'));
    });
  });
}

$(select2Init)
$(document).on('turbolinks:load', select2Init)
window.select2InitModal = select2InitModal;

$(document).on('change', '#content_filter_date_range', function (e) {
  if ($(this).val() === 'custom') {
    $('.date-row').removeClass('hidden');
  } else {
    $('.date-row').addClass('hidden');
    $('#content_filter_begin_date').val('');
    $('#content_filter_end_date').val('');
  }
});

