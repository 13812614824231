import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { Offline as OfflineIntegration } from '@sentry/integrations';

function metaTagContent(name) {
  return document.querySelector(`meta[name="${name}"]`).content;
}

if (metaTagContent('sentry-enabled') === 'true') {
  Sentry.init({
    dsn: 'https://FAKE@FAKE.ingest.sentry.io/1', // Will be set via tunnel
    environment: 'FAKE', // Will be set via tunnel
    release: 'FAKE', // Will be set via tunnel
    tunnel: '/sentry_tools/tunnel',
    debug: false,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          new RegExp(`^(${window.location.protocol}//([^/:]*\\.)?${window.location.host})?(/|$)`)
        ]
      }),
      new ExtraErrorDataIntegration(),
      new OfflineIntegration()
    ],
    tracesSampleRate: parseFloat(metaTagContent('sentry-traces-sample-rate')),
    transportOptions: {
      headers: {
        'X-CSRF-Token': metaTagContent('csrf-token')
      }
    }
  });
  Sentry.setUser({
    id: 'FAKE', // Will be set via tunnel
    email: 'FAKE', // Will be set via tunnel
    ip_address: 'FAKE' // Will be set via tunnel
  });
}
