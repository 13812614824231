require("trix");
require("@rails/actiontext");
require('bootstrap');
require('./google-analytics');
var select2 = require('../src/select2')
require('../src/sentry');
require('../stylesheets/application.scss')
import 'sweetalert/index.js'
import "@fortawesome/fontawesome-pro/js/all";

// https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/
var jQuery = require('jquery')
var d3 = require('d3')

global.$ = global.jQuery = jQuery;
global.select2 = select2;
global.d3 = d3;

require('jquery-ui/ui/widgets/sortable');

//TODO this should not use the jquery included dist
let lightbox2 = require('lightbox2/dist/js/lightbox-plus-jquery.js');

document.addEventListener('turbolinks:load', function () {
  lightbox2.option({
      'alwaysShowNavOnTouchDevices': true
  })
});

// app/javascript/packs/application.js
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
