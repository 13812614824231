// app/javascript/controllers/event_controller.js
import {Controller} from 'stimulus';
// import NestedForm from "stimulus-rails-nested-form";
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'


export default class extends NestedForm {
  static targets = ["template", "link", "container"];

  connect() {
    const element = document.getElementById("location_ids")
    element.dispatchEvent(new Event('change'));
    const expand = this.expandAccordionItem.bind(this)
    $(function () {
      const header = document.querySelector(".dynamic-location-fields")
      if(header){
        var content = header.querySelector('.address-form');
        expand(content, header, false)
      }
    })
    this.syncLocationFormComplete()
    this.showLocationDateTimeContainer()
  }

  initialize() {
    this.nextIndex = 99
  }

  handleLocationChange(e) {
    // overview:
    //   1. Start with an array of location ids from the select box
    //   2. Find all the locations already on the page
    //   3. Match them up to the selected location ids
    //      Add a newly selected options to the page
    //      Remove any that are no longer in the selected options
    const selectBox = e.currentTarget
    const selectedLocations = Array.from(selectBox.selectedOptions)
    const selectedLocationValues = selectedLocations.map(s => s.value)

    //look for locations that exist on the page
    let foundSelections = []
    let destroyedSelections = []

    document.querySelectorAll(".location-id").forEach((l) => {
      if (l.value != "") {
        foundSelections.push(l.value)
      }

      if (l.closest(".dynamic-location-fields").querySelector("input[name*='_destroy']").value == 1) {
        destroyedSelections.push(l.value)
      }
    })

    //find locations that are not currently on the page, i.e. a newly selected option in the dropdown
    const missingLocations = selectedLocations.filter(s => !foundSelections.includes(s.value));

    missingLocations.forEach((ml) => {
      this.add(e) //this is a function in stimulus-nested-forms
      // set the location id element added above to the new location selection
      document.querySelectorAll(".location-id").forEach((l) => {
        if (l.value == "") { //this is the newly added field
          l.value = ml.value
          l.closest(".dynamic-location-fields").querySelector('.location-header').innerHTML = '<span class="location-complete"></span>' + ml.text + '<i class="fas fa-chevron-right location-chevron-icon"></i>'

          // A timeout solves a wierd issue where the click doesn't fire
          // Assumption is the click event isn't quite attached yet by stimulus
          window.setTimeout(function(){
            l.closest(".dynamic-location-fields").querySelector('.location-header').click()
          },100)
        }
      })
    })

    //we need to handle if the user has already removed location and adding it back in
    const destroyedLocations = selectedLocations.filter(s => destroyedSelections.includes(s.value));
    destroyedLocations.forEach((ml) => {
      document.querySelectorAll(".location-id").forEach((l) => {
        if (l.value == ml.value) {
          l.closest(".dynamic-location-fields").style.display = 'block'
          l.closest(".dynamic-location-fields").querySelector("input[name*='_destroy']").value = 0
        }
      })
    })

    //now we need to handle removing a selection
    const removedLocations = foundSelections.filter(s => !selectedLocationValues.includes(s));
    document.querySelectorAll(".location-id").forEach((l) => {
      if (removedLocations.includes(l.value)) {
        let wrapper = l.closest(".dynamic-location-fields")
        if (wrapper.dataset.newRecord === 'true') {
          wrapper.remove()
        } else {
          wrapper.style.display = 'none'
          wrapper.querySelector("input[name*='_destroy']").value = 1
        }
      }
    })
  }

  removeAddress(e) {
    e.preventDefault();

    let wrapper = event.target.closest('.physical-address')
    wrapper.style.display = 'none'
    wrapper.querySelector("input[name*='_destroy']").value = 1
  }

  removeNewAddress(e) {
    e.preventDefault();

    let wrapper = event.target.closest('.physical-address')
    wrapper.style.display = 'none'
  }

  addLocation(e) {
    //this will add a new set of location & address fields, we only want the address fields and they need to be displayed with the other addresses within the same location
    this.add(e)

    document.querySelectorAll(".location-id").forEach((l) => {
      if (l.value == "") {
        const addressFieldsContainer = l.closest(".dynamic-location-fields").querySelector(".address-fields-container")
        e.currentTarget.before(addressFieldsContainer)

        const correctIdentifier = addressFieldsContainer.closest(".dynamic-location-fields").querySelector(".location-id").id.match(/_\d+_/)[0].replace(/_/g, "")

        this.nextIndex = this.nextIndex + 1
        addressFieldsContainer.querySelectorAll(".address-field").forEach((a) => {
          a.name = a.name.replace(/\[0\]/g, `[${this.nextIndex}]`);
          a.name = a.name.replace(/\[\d+\]/, `[${correctIdentifier}]`);
          a.id = a.id.replace(/_0_/g, `_${this.nextIndex}_`);
          a.id = a.id.replace(/_\d+_/, `_${correctIdentifier}_`);
        })

        addressFieldsContainer.querySelector('.physical-address').classList.add('additional-address')
        addressFieldsContainer.querySelector('.additional-address-header').classList.remove('hidden')
        l.closest(".dynamic-location-fields").remove()
      }
    })
  }

  locationHeaderClick(e) {
    var accordions = document.querySelectorAll(".dynamic-location-fields")

    // collapse all
    const expand = this.expandAccordionItem.bind(this)
    const collapse = this.collapseAccordionItem.bind(this)
    accordions.forEach(function (accordion) {
      var header = accordion.querySelector(".location-header")
      var content = accordion.querySelector('.address-form');
      if (header == e.currentTarget) { //show clicked item
        if(content.classList.contains("invisible")){
          expand(content, header)
        }
        //handle chevron
      } else { //hide others
        collapse(content)
        //handle chevron
      }
    });
  }

  expandAccordionItem(content, header = null, scroll = true) {
    if(!header) return;

    $(content).slideUp("fast")
    content.classList.remove("invisible")
    $(content).slideDown("fast")
    content.closest(".dynamic-location-fields").querySelector(".location-chevron-icon").classList.add('active')

    if(scroll) {
      header.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    }
  }

  collapseAccordionItem(content) {
    $(content).hide()
    content.classList.add("invisible")
    $(content).show()
    content.closest(".dynamic-location-fields").querySelector(".location-chevron-icon").classList.remove('active')
  }

  checkFormComplete(e) {
    if(e.currentTarget.value == ""){
      e.currentTarget.closest(".dynamic-location-fields").querySelector(".location-complete").innerHTML = ""
    } else{
      this.syncLocationFormComplete()
    }
  }

  syncLocationFormComplete(){
    document.querySelectorAll(".location-complete").forEach((lc) => {
      const fieldsContainer = lc.closest(".dynamic-location-fields")
      const addressFields = fieldsContainer.querySelectorAll(".address-field")
      let complete = true
      addressFields.forEach((af) => {
        if(af.value == "" && !af.name.match(/address_line_two/)) {
          complete = false
        }
      })
      if(complete){
        lc.innerHTML = "&#10003;"
      }
    })
  }

  eventTypeClick(e){
    this.showLocationDateTimeContainer()
  }

  showLocationDateTimeContainer(){
    document.querySelectorAll('input[name="event[event_type]"').forEach((et) => {
      if(et.checked){
        const startTimeInput = document.getElementById("event_start_time")
        const endTimeInput = document.getElementById("event_end_time")
        if(et.value == "day_and_time_event"){
          startTimeInput.closest(".time-container").style.display = "block"
          endTimeInput.closest(".time-container").style.display = "block"
          document.getElementById('location-date-time-container').style.display = "block"
          document.getElementById('date-times-only-container').style.display = "none"
          document.getElementById('event_start_date').disabled = false
          document.getElementById('event_end_date').disabled = false
          document.getElementById('retail-start-date').disabled = true
          document.getElementById('retail-end-date').disabled = true
        } else if(et.value == "timeframe_event") {
          startTimeInput.closest(".time-container").style.display = "none"
          endTimeInput.closest(".time-container").style.display = "none"
          document.getElementById('location-date-time-container').style.display = "block"
          document.getElementById('date-times-only-container').style.display = "none"
          document.getElementById('event_start_date').disabled = false
          document.getElementById('event_end_date').disabled = false
          document.getElementById('retail-start-date').disabled = true
          document.getElementById('retail-end-date').disabled = true
        } else if(et.value == "product") {
          document.getElementById('location-date-time-container').style.display = "none"
          document.getElementById('date-times-only-container').style.display = "block"
          document.getElementById('event_start_date').disabled = true
          document.getElementById('event_end_date').disabled = true
          document.getElementById('retail-start-date').disabled = false
          document.getElementById('retail-end-date').disabled = false
        }
      }
    })
  }
}
