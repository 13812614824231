import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'campaigns'
  ]

  clickCampaign(e) {
    e.target.closest('.campaign-card').classList.toggle('active')
  }

  submitSelection(e) {
    const activeCampaigns = document.querySelectorAll('.active')
    const campaignIds = Array.from(activeCampaigns).map(function(e) {
      return e.dataset.id
    })
    var selectElement = document.querySelector('select[name="selected_campaigns[]"]')

    Array.from(selectElement.options).forEach((option) => {
      if (campaignIds.includes(option.value)) {
        option.selected = true
      }
    })
  }
}
